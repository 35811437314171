const getParamData = (url = '') => {
  return getAllUrlParams(url === '' ? window.location.href : url)
}

const hasParams = (url) => {
  const obj = getAllUrlParams(url)
  return Object.keys(obj).length > 0
}

const addParams = (url = '', params = {}) => {
  if (typeof url !== 'string') {
    console.error('Url parameter should be string')
    return false
  }
  if (typeof params === 'object') {
    let newString = url + (hasParams(url) ? '' : '?')
    Object.keys(params).map(key => {
      newString += '&' + key + '=' + params[key]
    })
    return newString
  }
  return url
}

function getAllUrlParams (url) {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1)

  // we'll store the parameters here
  const obj = {}

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0]

    // split our query string into its component parts
    const arr = queryString.split('&')

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=')

      // set parameter name and value (use 'true' if empty)
      const paramName = a[0]
      let paramValue = typeof (a[1]) === 'undefined' ? true : a[1]

      // (optional) keep case consistent
      // paramName = paramName.toLowerCase()
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase()

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '')
        if (!obj[key]) obj[key] = []

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1]
          obj[key][index] = decodeURIComponent(paramValue)
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(decodeURIComponent(paramValue))
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = decodeURIComponent(paramValue)
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]]
          obj[paramName].push(decodeURIComponent(paramValue))
        } else {
          // otherwise add the property
          obj[paramName].push(decodeURIComponent(paramValue))
        }
      }
    }
  }

  return obj
}

export {
  addParams,
  hasParams,
  getParamData,
  getAllUrlParams
}
