import Vue from 'vue'

const LoadingButton = {
  name: 'loading-button',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    theme: {
      validator (value) {
        return ['green', 'yellow', 'blue'].indexOf(value) !== -1
      },
      default: 'yellow'
    }
  },
  template: `
  <button class="button text-black font-extrabold uppercase md:text-20" 
          :class="{ 
            'bg-yellow-500 hover:bg-yellow-700': theme === 'yellow',
            'button--border-bottom-greenish button--greenish': theme === 'green'
          }" 
          @click.prevent="$emit('click')">
      <transition name="prev-fast" mode="out-in">
          <div v-if="loading" class="animated">
              <div class="sk-chase mx-auto">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
          <span v-else class="animated"><slot></slot></span>
      </transition>
  </button>
  `
}
Vue.component('loading-button', LoadingButton)

export default LoadingButton
