import Vue from 'vue'

const InputComponent = {
  name: 'InputComponent',
  props: {
    value: {
      type: [String, Number],
      default: () => ('')
    },
    name: {
      type: String,
      default: 'default'
    },
    id: {
      type: String,
      default: 'default'
    },
    object: {
      type: Object,
      default: () => ({})
    },
    inputMode: {
      type: String,
      default: 'text'
    },
    maxLength: {
      type: [Boolean, String],
      default: false
    },
    capitalize: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    smallerHeight: {
      type: Boolean,
      default: false
    },
    icon: {
      type: [String, Boolean],
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    instantBlurError: {
      type: Boolean,
      default: true
    },
    checkout: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      focus: false
    }
  },
  mounted() {
    if(this.autofocus) {
      this.$refs.inputRef.focus()
    }
  },
  methods: {
    blur () {
      this.focus = false
      if (this.object.$touch) this.object.$touch()
      this.$emit('blur', { id: this.id, value: this.value })
    },
    setFocus () {
      this.focus = true
    }
  },
  template: `
   <div :class="{ 'checkout-error': object.$error }">
        <label
            class="block mb-2"
            :class="{'text-black text-16': !checkout, 'text-grey-500 text-15': checkout}"
            :for="id">
          {{ this.name }}
        </label>
       <div class="flex flex-grow justify-center group">
           <div v-if="icon !== false"
                :class="{'border-red-700' : object.$error, 'border-grey-300': focus, 'border-primary-input': !focus }"
                class="animated group-hover:border-gray-700 focus:outline-none w-24 border flex items-center justify-center -mr-3 z-50 bg-white rounded-l-lg"
           >
               <img v-if="typeof icon === 'string'" :src="icon" alt="icon">
               <slot v-else name="icon"></slot>
           </div>
           <input
                   :value="value"
                   @input="$emit('input', $event.target.value)"
                   @blur="instantBlurError ? blur() : null"
                   @focus="setFocus()"
                   class="appearance-none animated rounded-lg px-5 w-full group-hover:border-2 active:border-black focus:shadow-focus-input focus:outline-none group-hover:shadow-focus-input group-hover:border-black hover:shadow-focus-input focus:shadow-focus-input focus:outline-none"
                   :class="{'border-grey-300 border shadow-error-input' : !object.$error, 'border-red-700 border-2 shadow-error-input' : object.$error, 'capitalize': capitalize, 'h-18': smallerHeight === true, 'h-20': smallerHeight === false}"
                   :type="type"
                   :placeholder="placeholder"
                   :id="id"
                   :maxlength="maxLength"
                   :inputmode="inputMode"
                   ref="inputRef">
       </div>
         <div v-cloak>
             <transition name="slide-down" mode="out-in">
                 <div v-if="object.$error" class="text-red-700 animated" :class="{'text-16 mt-4 font-semibold': checkout, 'text-red-700 text-16 font-semibold mt-2': !checkout}" >
                     <div class="animated" v-if="object.required === false">Field is required</div>
                     <div class="animated" v-else-if="object.notNumbers === false">Please enter letters only</div>
                     <div class="animated" v-else-if="object.email === false">Incorrect email</div>
                     <div class="animated" v-else-if="object.minLength === false">Please enter at least {{object.$params.minLength.min}} characters</div>
                     <div class="animated" v-else-if="object.cardHolderValidation === false">Incorrect format</div>
                     <div class="animated" v-else-if="object.sameAs === false">Please enter same value</div>
                     <div class="animated" v-else-if="object.notSameAsEmail === false">Friend email address cannot be the same as your email address.</div>
                 </div>
             </transition>
         </div>
  </div>
`
}

Vue.component('InputComponent', InputComponent)

export default InputComponent
